/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

global.$ = global.jQuery = $;

require('bootstrap');

// any CSS you import will output into a single css file (app.css in this case)
import './../styles/app.scss';
//import './../styles/input.css';
import './../styles/buttons.css';
import { Modal, Popover } from 'bootstrap';
import { changedGamesCount } from './home';

import './notifications';
  
//import $ from 'jquery'

// start the Stimulus application
//import './bootstrap';



(function($, window)
{
    window.copyToClipboard = function(id){
        var copyText = document.getElementById(id);

        if(copyText == null)
        {
            console.log("No element found");
            return;
        }

        copyText.focus();
        copyText.select();
        copyText.setSelectionRange(0, 9999);

        navigator.clipboard.writeText(copyText.value);
    }

    window.openSideDrawer = function() {
        // open sidebar
        $('#sidebar').addClass('active');
        // fade in the overlay
        $('.overlay').addClass('active');
        $('.collapse.in').toggleClass('in');
        //$('a[aria-expanded=true]').attr('aria-expanded', 'false');
    }
      
    window.closeSideDrawer = function() {
        
        /*document.getElementById("side-drawer").style.left = "-336px";
        document.getElementById("side-drawer-void").classList.add("d-none");
        document.getElementById("side-drawer-void").classList.remove("d-block");*/
    }

    let sidebar = $('.sidebar'),
        allDropdown = $('.sidebar-dropdown'),
        width = sidebar.width();
    
    function hideSidebar() {
        console.log("Hide");
        if (!sidebar.hasClass('hidden')) {
        sidebar.one('transitionend', function () {
            sidebar.addClass('hidden');
        });
        sidebar.width(0);
        }
    }
    function showSidebar() {
        sidebar.removeClass('hidden');
        sidebar.width(width);
    }
    function dropdown() {
        let parent = $(this).parent('.sidebar-dropdown');
        if (parent.hasClass('active')) {
        parent.removeClass('active');
        } else {
        allDropdown.removeClass('active');
        parent.addClass('active');
        }
    }
    
    $('#btn-hide, .btn-sidebar-close').on('click', hideSidebar);
    $('#btn-show, .btn-sidebar-show').on('click', showSidebar);
    $('.sidebar-dropdown a').on('click', dropdown);

    window.acceptCookies = function()
    {
        var date = new Date();
        var time = date.getTime();
        var expireTime = time + ((16 * 7 * 24 * 60 * 60) * 1000);

        date.setTime(expireTime);

        document.cookie = 'cookies_accepted=1;expires=' + date.toUTCString() + ';path=/';

        var container = document.getElementById('cookies_consent_container');
        container.remove();
    }

    window.removeAlertMessage = function(element, alertId)
    {
        var data = new FormData;
        data.append('id', alertId);

        console.log(element);
        console.log(alertId);

        sendAjax('/request/profile/profile_remove_alert', data, function(response){
            
            /*var parent = element.parent().parent();
            parent.remove();*/
        })
    }

    var firstInfoSeenModalElement = document.querySelector('.first-info-modal');

    if(firstInfoSeenModalElement !== null){
        const firstInfoSeenModal = new Modal(firstInfoSeenModalElement, null);
        firstInfoSeenModal.show();
    }

    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
    const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new Popover(popoverTriggerEl, {
        html: true
    }));
    

    var usersettingsElements = document.querySelectorAll('[data-usersettings-change]');
    usersettingsElements.forEach(function(element){
        var change = element.dataset.usersettingsChange;
        element.addEventListener('change', function(e){
            var value = this.value;
            changeUserSetting(change, value, function(){
                if(change == 3){
                    changedGamesCount();
                } else if(change == 2){
                    window.location = "";
                }
                
            });
        })
    })

}(jQuery, window))



/*const socket = new WebSocket("ws://localhost:3001");

socket.addEventListener("open", function(){
    console.log("CONNECTED");
})

socket.addEventListener('close', function(e){
    console.log("Websocket disconnected");
})

export function sendWebsocketMessage(topic, json = null){
    var socketMessage = {topic: topic};

    if(json != null){
        socketMessage = json;
        socketMessage.topic = topic;
    }
    socket.send(JSON.stringify(socketMessage));
}

export function messageListener(topic, callback){
    socket.addEventListener("message", function(e){
        try {
            const message = JSON.parse(e.data);
            console.log(message);


            if(message.topic == topic){
                callback(message);
            }
        } catch(e){
            console.log(e);
        }
    })
}*/

const ele = document.getElementById('drag_container');
if(ele != null){
    ele.scrollLeft = 0;
    ele.scrollTop = 0;

    let pos = { top: 0, left: 0, x: 0, y: 0};

    const mouseDownHandler = function(e){
        ele.style.cursor = 'grabbing';
        ele.style.userSelect = 'none';

        pos = {
            left: ele.scrollLeft,
            top: ele.scrollTop,
            x: e.clientX,
            y: e.clientY
        };
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
    }

    const mouseMoveHandler = function(e){
        const dx = e.clientX - pos.x;
        const dy = e.clientY - pos.y;

        ele.scrollTop = pos.top - dy;
        ele.scrollLeft = pos.left - dx;
    }

    const mouseUpHandler = function() {
        ele.style.cursor = 'grab';
        ele.style.removeProperty('user-select');

        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
    };

    ele.addEventListener('mousedown', mouseDownHandler);
}

let notificationIconButton = document.querySelector('.notification-open-icon');
if(notificationIconButton != null){
    notificationIconButton.addEventListener('click', function(){
        sendAjax('/request/profile/seen_notifications', null, function(response){
            if(response.success){
                var icon = document.querySelector('.notification-bell');
                icon.classList.remove('fas');
                icon.classList.add('far');
                icon.style.color = "";
            }
        });
    })
}

var closeFirstInfoButton = document.getElementById('close_first_info');
if(closeFirstInfoButton != null){
    closeFirstInfoButton.addEventListener('click', function(){
        sendAjax('/request/profile/seen_first_info', null, function(response){
            console.log(response);
        })
    })
    
}

/*var countdownContainer = document.querySelector('.countdown_container');
if(countdownContainer != null){
    sendAjax('/request/profile/countdown_to_first_match', new FormData, function(response){

        if(response.success){
            var second  = 1000;
            var minute  = second * 60;
            var hour    = minute * 60;
            var day     = hour * 24;

            var countDown = response.date * 1000;

            let x = setInterval(function(){

                let now = new Date().getTime()
                let distance = countDown - now;

                var daysLeft    = Math.floor(distance / (day));
                var hoursLeft   = Math.floor((distance % (day)) / (hour));
                var minutesLeft = Math.floor((distance % (hour)) / (minute));
                var secondsLeft = Math.floor((distance % (minute)) / second);

                document.getElementById('days_text').innerText = daysLeft == 1 ? "dag" : "dagar";
                document.getElementById('hours_text').innerText = hoursLeft == 1 ? "timme" : "timmar";
                document.getElementById('minutes_text').innerText = minutesLeft == 1 ? "minut" : "minuter";
                document.getElementById('seconds_text').innerText = secondsLeft == 1 ? "sekund" : "sekunder";

                document.getElementById('days').innerText       = daysLeft;
                document.getElementById('hours').innerText      = hoursLeft;
                document.getElementById('minutes').innerText    = minutesLeft;
                document.getElementById('seconds').innerText    = secondsLeft;

                if(distance < 0){
                    countdownContainer.style.display = "none";

                    clearInterval(x);
                }
            }, 0)

            console.log(countDown);

        } 

    });
}*/
$(document).ready(function() {
    //$('[data-toggle="popover"]').popover();


    $("input[type='text'], input[type='password'], input[type='email'], input[type='number'], .floatPlaceholder").each(function(e) {
        if($(this).attr("class") != "noFloatInput" && $(this).attr("placeholder") != null){
            $(this).wrap('<fieldset></fieldset>');
            var tag = $(this).attr("placeholder");
            //var tag= $(this).data("tag");
            $(this).attr("placeholder", "");
            $(this).after('<label for="name" class="inputLabel">' + tag + '</label>');
            if($(this).val() != ""){
                $(this).next().removeClass('inputLabel');
                $(this).next().addClass('stay');
            }
        }
    });

    $('.inputLabel').on("click", function(){
        $(this).prev().focus();
    })

    $('body').on('blur', 'input', function() {
        if($(this).attr("class") != "noFloatInput" && $(this).attr('type') != "checkbox" && $(this).attr('type') != "radio"){
            if (!$(this).val() == "" && $(this).attr("type") != "radio") {
              $(this).next().addClass('stay');
              $(this).next().removeClass('inputLabel');
            } else {
              $(this).next().removeClass('stay');
              $(this).next().addClass('inputLabel');
            }
        }
    });
    
    $("input[type='text'], input[type='password'], input[type='email'], input[type='number']").trigger('blur');


    /*$("#sidebar").mCustomScrollbar({
        theme: "minimal"
    });*/

    $('#dismiss, .overlay').on('click', function () {
        // hide sidebar
        $('#sidebar').removeClass('active');
        // hide overlay
        $('.overlay').removeClass('active');
    });

    $('#sidebarCollapse').on('click', function () {
        // open sidebar
        $('#sidebar').addClass('active');
        // fade in the overlay
        $('.overlay').addClass('active');
        $('.collapse.in').toggleClass('in');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
    });
    

});

export function isEmpty(value){
    if(value == null || value == "" || value == "undefined"){
        return true;
    } 
    return false;
}

export function generalAjaxErrorHandling(response){
    if(response.error || response.error == "undefined"){
        if(response.info == "" || response.info == "undefined" || response.info == null){
            createNotification("Ingen kontakt med server", 'error');
        } else {
            createNotification(response.info, 'error');
            console.log(response.info);
        }
    } else {
        if(!isEmpty(response.redirect)){
            location.href = site_url + response.redirect;
        } else if(!response.no_notif){
            createNotification(response.info, 'info');

            if(response.dialog != null && response.dialog != ""){
                removeFullDialog(response.dialog);
            }
        }
    }
}

export async function sendAjax(route, formData, callbackFunction, callbackSent = null){
    var xhttp = new XMLHttpRequest();

    xhttp.onreadystatechange = function() {
        if(this.readyState == 4 && this.status == 200){
            if(this.responseText.includes("Fatal error:")){
                var response = {'error':'true', 'info':'Kunde inte nå server'};
                var response = {'error':'true', 'info': this.responseText};
            } else if(this.responseText.includes("Warning:")){
                var response = {'error':'true', 'info':'Kunde inte nå server'};
                var response = {'error':'true', 'info': this.responseText};
            } else {
                var response = JSON.parse(this.responseText);
            }
            console.log("Statechanged: ", response);
            callbackFunction(response);
        }
    };

    xhttp.open("POST", route, true);

    xhttp.send(formData);

    if(callbackSent != null)
        callbackSent();
}

export function sendUrlAjax(route, callbackFunction){
    var xhttp = new XMLHttpRequest();

    xhttp.onreadystatechange = function(){
        if(this.readyState == 4 && this.status == 200)
        {
            if(this.responseText.includes("Fatal error:")){
                var response = {'error':'true', 'info':'Kunde inte nå server'};
                var response = {'error':'true', 'info': this.responseText};
            } else if(this.responseText.includes("Warning:")){
                var response = {'error':'true', 'info':'Kunde inte nå server'};
                var response = {'error':'true', 'info': this.responseText};
            } else {
                var response = JSON.parse(this.responseText);
            }
            callbackFunction(response);
        }
    }

    xhttp.open("GET", route, true);
    xhttp.send();
}

export const NOTIFICATION_LEVEL_REGULAR = "regular";
export const NOTIFICATION_LEVEL_WARNING = "warning";
export const NOTIFICATION_LEVEL_ERROR = "error";
export const NOTIFICATION_LEVEL_SUCCESS = "success";

export function createNotification(title, message, level){

    showPushNotification(null, title, message);

    return;

    var notificationContainer = document.querySelector('.notification-container');
    var notificationContent = notificationContainer.querySelector('.notification-content');
    var notificationTitle = notificationContent.querySelector('.notification-title');
    var notificationBody = notificationContent.querySelector('.notification-body');

    if(level == null || level == 'undefined')
        level = NOTIFICATION_LEVEL_REGULAR

    notificationContent.classList.remove('warning');
    notificationContent.classList.remove('error');
    notificationContent.classList.remove('success');
    notificationContent.classList.remove('regular');
    notificationContent.classList.add(level);

    if(notificationTitle !== null) {
        notificationTitle.innerHTML = title;
    } 
    notificationBody.innerHTML = message;

    var opacity = 0.1;
    notificationContent.style.display = 'block';
    var timer = setInterval(function () {
        if (opacity >= 1){
            clearInterval(timer);
            notificationContent.classList.add('show');
            notificationContent.classList.remove('hide');
        }
        notificationContent.style.opacity = opacity;
        notificationContent.style.filter = 'alpha(opacity=' + opacity * 100 + ")";
        opacity += opacity * 0.25;
    }, 10);

    notificationContent.addEventListener('click', closeNotifications);
    setTimeout(closeNotifications, 8000);
}

export function showPushNotification(id, title, message, link){
    
    var contentElement = createNotificationElement(link);

    if(id !== null) contentElement.dataset.id = id;

    var titleElement = contentElement.querySelector('.notification-title');
    var bodyElement = contentElement.querySelector('.notification-body');

    titleElement.innerHTML = title;
    bodyElement.innerHTML = message;

    var timeout = setTimeout(function(){
        closePushNotification(contentElement)
    }, 15000);

    contentElement.addEventListener('click', function(e){
        closePushNotification(contentElement)
        clearTimeout(timeout);
    });
}

function createNotificationElement(link){
    var notificationContainer = document.querySelector('.notification-container');

    var linkElement = document.createElement("a");
    if(link != null){
        linkElement.href = link;
    }

    var contentElement = document.createElement("div");

    contentElement.classList.add("notification-content");
    contentElement.classList.add("regular");
    contentElement.classList.add("show");

    var titleElement = document.createElement("div");
    titleElement.classList.add("notification-title");

    var bodyElement = document.createElement("div");
    bodyElement.classList.add("notification-body");

    notificationContainer.appendChild(contentElement);
    contentElement.appendChild(linkElement);

    linkElement.appendChild(titleElement);
    linkElement.appendChild(bodyElement);

    return contentElement;
}

function closePushNotification(element){
    if(element == null) return;
    
    var opacity = 1;
    var timer = setInterval(function () {
        if (opacity <= 0.1){
            clearInterval(timer);
            element.classList.remove('show');
            element.style.display = 'none';
            element.classList.add('hide');
        }
        element.style.opacity = opacity;
        element.style.filter = 'alpha(opacity=' + opacity * 100 + ")";
        opacity -= opacity * 0.25;
    }, 10);
    
    if(!element.hasAttribute('data-id')) return;

    var data = new FormData;
    data.append('id', element.dataset.id);

    sendAjax("/request/profile/notif_pushed", data, function(response){

    });
}

function closeNotifications(element){
    let elements = document.querySelectorAll('.notification-content');
    
    console.log(elements);
    if(elements.length == 0) return;


    elements.forEach(function(el){
        if(el.classList.contains('show')){

            var opacity = 1;
            var timer = setInterval(function () {
                if (opacity <= 0.1){
                    clearInterval(timer);
                    el.classList.remove('show');
                    el.style.display = 'none';
                    el.classList.add('hide');
                }
                el.style.opacity = opacity;
                el.style.filter = 'alpha(opacity=' + opacity * 100 + ")";
                opacity -= opacity * 0.25;
            }, 10);
        }
    });
    
}

/*export function createNotification(content, type){
    var obj = {'msg': content,'type':type};

    var notif_container = document.getElementById('notifications_container');
    var element = document.createElement("div");

    var element_class = 'notification_msg';

    switch(type){
        case 'error':
            element_class += ' critical';
            break;
        
        case 'info':
            element_class += '';
            break;
    }
    
    element.className = element_class;
    element.innerHTML = content;

    notif_container.prepend(element);
    setTimeout(
        function(){ 
            animate(element, 500);
        }
        , 5000);
}*/

export const NOTIF_PRIMARY = "primary";
export const NOTIF_DANGER  = "danger";
export const NOTIF_SUCCESS = "success";

export function alertNotification(content, type = "primary")
{    
    if(content == null || content == "")
        return;

    var container = document.getElementById('notifications_container');

    if(container.childElementCount <= 0)
        container.style.display = "block";

    var alertElement = document.createElement("div");
    alertElement.setAttribute('class', 'alert alert-' + type);
    alertElement.setAttribute('role', 'alert');
    alertElement.appendChild(document.createTextNode(content));

    container.prepend(alertElement);

    setTimeout(function(){
        alertElement.remove();

        if(container.childElementCount <= 0){
            container.style.display = "none";
        }
    }, 5000)
}

export function changeUserSetting(change, value, successCallback)
{
    var data = new FormData;

    data.append('change', change);
    data.append('value', value);

    sendAjax('/request/user_settings', data, function(response){
        console.log(response);
        if(!response.success && response.message){
            if(response.message) createNotification(response.title, response.message, NOTIF_DANGER);
        } else {
            //if(response.message) createNotification(response.title, response.message, NOTIF_SUCCESS);
            successCallback();
        }
    })
}

// Animates the notification when removed
function animate(element, runTime){
    var pos = element.offsetHeight;

    var millis = runTime == null || runTime == 'undefined' ? 5 : runTime / pos;

    var padding_top     = element.style.paddingTop.replace("px", "");
    var padding_bottom  = element.style.paddingBottom.replace("px", "");

    pos = pos - padding_top - padding_bottom;

    var border_size = getComputedStyle(element,null).getPropertyValue('border-width').replace("px", "");
    
    element.style.overflow = 'hidden';

    window.setInterval(go, millis);

    function go(){
        if(pos <= 0 && padding_top <= 0 && padding_bottom <= 0){
            element.outerHTML = "";
            border_size = border_size - 0.1;
            element.style.borderWidth = border_size;

            //clearInterval(interval);
        }

        if(padding_top > 0){
            padding_top     = padding_top - 1;
            element.style.paddingTop    = padding_top;
        } else if(padding_bottom > 0) {
            padding_bottom  = padding_bottom - 1;
            element.style.paddingBottom = padding_bottom;
        } else if(pos > 0){
            pos--;
            element.style.height = pos + 'px';
            element.style.lineHeight = pos + 'px';
        }
    }
}

/*function updateNotifications(){

    $('#notification-bell-container').load(window.location.href + " #notification-bell-container", function(){
        
    });
    
    setTimeout(updateNotifications, 15000);
}

var bell_container = document.getElementById("notification-bell-container");
if(bell_container != null){
    updateNotifications();
}*/

