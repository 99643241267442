import { changeUserSetting } from "./app";

var timeout = null;

function updateLiveOverview(){
    console.log("Refresh content...");
    $('#live-refresh-upcoming').load(window.location.href + " #live-refresh-upcoming");
    timeout = setTimeout(updateLiveOverview, 60000); 
}

updateLiveOverview();

export function changedGamesCount(){
    clearTimeout(timeout);

    updateLiveOverview();
}