import { createNotification, sendAjax, showPushNotification } from "./app";


function updateNotifications(){
    /*console.log("Update notifications");

    sendAjax('/request/profile/not_pushed_notifications', null, function(response){
        console.log(response);
        response.notifications.forEach(function(object){
            console.log("Notif: ", object);
            if(!object.notif_pushed){
                showPushNotification(object.id, object.title, object.message, object.link);
            }
        })
    });

    setTimeout(updateNotifications, 30000);*/
}

var bell_container = document.getElementById("notification-bell-container");
if(bell_container != null){
    updateNotifications();
}



/**
 * ServiceWorker stuff
 */

var hasPermission = false;

const checkPermission = ()  => {
    console.log("Check permissions");
    if(!('serviceWorker') in navigator){
        throw new Error("No support for service worker");
    }

    if(!('Notification') in  window){
        throw new Error("No support from notification API");
    }

    hasPermission = Notification.permission == "granted";
    
}

const registerSW = async () => {

    if(!hasPermission){
        console.log("No permission to push");
        return;
    } 

    await navigator.serviceWorker.register('/sw.js?v=0.1.14', {
        scope: '/'
    }).then(function(registration){
        if(registration.installing){
            console.log("Service worker installing");
        } else if(registration.waiting){
            console.log("Service worker installed");
        } else if(registration.active){
            console.log("Service worker active");
        }

        navigator.serviceWorker.addEventListener("message", (event) => {
            console.log("Message from SW: ", event.data);
            createNotification(null, event.data);
        });
    });
}

const requestNotificationPermission = async () => {
    const permission = await Notification.requestPermission();

    if(permission !== 'granted'){
        throw new Error("Permissions not granted");
    } else {
        console.log("Permissions granted");
        hasPermission = true;
        registerSW();
    }
}

var requestPermissionBtn = document.getElementById('request_chat_permission');
if(requestPermissionBtn !== null){
    requestPermissionBtn.addEventListener('click', function(e){
        requestNotificationPermission();
    })
}

const main = async () => {
    checkPermission();
    await registerSW();
    
    //reg.showNotification("Test", {body: "Haaaasdjasjdasd"});
} 

main();

var sendNotificationBtn = document.querySelector('.sendNotificationBtn');
if(sendNotificationBtn !== null){
    sendNotificationBtn.addEventListener('click', function(e){
        console.log("Send notif");
        
        sendAjax('/request/test_send_notification', null, function(response){
            console.log(response);
        })
    });
}

